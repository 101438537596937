import React from 'react';
import "./Menu.css";

class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selecteditem: props.selecteditem
		};
	}

	switchMenu(key) {
		if (this.state.selecteditem !== key) {
			this.setState({"selecteditem": key});
			if (this.props.onitemselected !== undefined) {
				this.props.onitemselected(key);
			}
		}
	}

	getItemClass(key) {
		return key === this.state.selecteditem
			? key + " menu-item selected"
			: key + " menu-item";
	}

	render() {
		return (<div className="menu">
			{
				this.props.items.map((item) => {
					return (<div key={item.key} className={this.getItemClass(item.key)} onClick={() => this.switchMenu(item.key)}>
						{item.icon}
						<div className="menu-label">{item.label}</div>
					</div>)
				})
			}
		</div>)
	}
}
export default Menu;
