import React from 'react';
import "./CommandCenter.css";
import Dashboard from './components/dashboard/Dashboard.js';
import SignInGrid from './components/signInGrid/SignInGrid';
import AdminDates from './components/adminDates/AdminDates';
import AdminKickers from './components/adminKickers/AdminKickers';
import UserData from './components/userData/UserData';
import Menu from '../../../common/components/menu/Menu';
import {CurrentUserContext} from "../../../common/webservice/user/User";
import {ReactComponent as DashboardIcon} from '../../../common/assets/icons/dashboard.svg';
import {ReactComponent as CalendarIcon} from '../../../common/assets/icons/calendar.svg';
import {ReactComponent as AdminDatesIcon} from '../../../common/assets/icons/adminDates.svg';
import {ReactComponent as AdminPlayerIcon} from '../../../common/assets/icons/adminPlayer.svg';
import {ReactComponent as UserIcon} from '../../../common/assets/icons/user.svg';
import {ReactComponent as LogoutIcon} from '../../../common/assets/icons/logout.svg';

class CommandCenter extends React.Component {
	constructor(props) {
		super(props);
		this.user = undefined;
		this.state = {
			currentPage: "dashboard"
		};
	}

	getCurrentView() {
		switch (this.state.currentPage) {
			case "dashboard":
				return this.getDashboard();
			case "calender":
				return this.getGetCalender();
			case "adminDates":
				return this.getAdminDates();
			case "adminKickers":
				return this.getAdminKickers();
			case "userData":
				return this.getUserData();
			case "logout":
				this.user.setUser();
				break;
			default:
				return this.getDefault();
		}
	}

	getAdminDates() {
		return (<AdminDates ></AdminDates>);
	}

	getAdminKickers() {
		return (<AdminKickers></AdminKickers>);
	}

	getUserData() {
		return (<UserData></UserData>)
	}

	getDashboard() {
		return (<Dashboard ></Dashboard>);
	}

	getGetCalender() {
		return (<SignInGrid></SignInGrid>);
	}

	getDefault() {
		return (<div>default</div>);
	}

	getMenuItems(user) {
		var list = [];
		if (user) {
			list.push({"key": "dashboard", "label": "Dashboard", "icon": <DashboardIcon/>});
			list.push({"key": "calender", "label": "Kalender", "icon": <CalendarIcon/>});
			if (user.access === "USER" || user.access === "SUPERADMIN" || user.access === "ADMIN") {
				list.push({
					"key": "userData", "label": "Meine Daten", "icon": <UserIcon/>,
				});
			}
			if (user.access === "SUPERADMIN" || user.access === "ADMIN") {
				list.push({
					"key": "adminDates", "label": "Admin - Datum", "icon": <AdminDatesIcon/>,
				});
			}
			if (user.access === "SUPERADMIN") {
				list.push({
					"key": "adminKickers", "label": "Admin - Spieler", "icon": <AdminPlayerIcon/>,
				});
			}
			list.push({"key": "logout", "label": "Logout", "icon": <LogoutIcon/>});
		}

		return list;
	}

	onMenuSelected(item) {
		if (item === "logout") {
			this.user.setUser();
		} else {
			this.setState({"currentPage": item});
		}
	}

	createMenu(user) {
		this.user = user;
		var items = this.getMenuItems(user.currentUser);
		return (<Menu selecteditem={this.state.currentPage} items={items} onitemselected={(value) => this.onMenuSelected(value)}></Menu>)
	}

	componentWillUnmount() {
		this.user = undefined;
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => (<div id="commandCenter">
					{this.createMenu(user)}
					{this.getCurrentView()}
				</div>)
			}
		</CurrentUserContext.Consumer >)
	}
}
export default CommandCenter;
