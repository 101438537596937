import React from 'react';
import "./AdminKickers.css";
import {CurrentUserContext, UserService,} from "../../../../../common/webservice/user/User.js";
import DataGrid from "../../../../../common/components/dataGrid/DataGrid";

class AdminKickers extends React.Component {
	constructor(props) {
		super(props);
		this.user = undefined;
		this.state = {
			gridConfiguration: undefined
		};
	}

	onCheckbox(event, data, column) {
		if (event.currentTarget.checked) {
			data[column.labelField] = column.info["selected"];
		} else {
			data[column.labelField] = column.info["notSelected"];
		}
		this.updatePlayer("change", data);
		this.setState({
			[event.target.name]: data[column.labelField]
		});
	}

	onButton(event, data, column) {
		switch (column.id) {
			case "delete":
				this.updatePlayer("delete", data).then((result) => {
					try {
						result.json().then((json) => {
							if (json && json.success) {
								var index = this.state.gridConfiguration.data.indexOf(data);
								if (index >= 0) {
									this.state.gridConfiguration.data.splice(index, 1);
									this.setState({"gridConfiguration": this.state.gridConfiguration});
								}
							}
						});
					} catch (e) {}
				});
				break;
			default:

		}
	}

	onPasswordChange(event, data, column) {
		var value = event.target.value;
		data[column.id] = value;
		var target = event.target.name
		this.setState({ [target]: data[column.id] });
	}

	onSavePassword(event, data, column) {
		if (data[column.id] && data[column.id].length > 0) {
			var target = event.target.name
			this.updatePlayer("change", data).then((result) => {
				try {
					result.json().then((json) => {
						if (json && json.success) {
							this.setState({ [target]: data[column.id] });
						}
					});
				} catch (e) { }
			});
		}
	}

	onTextChange(event, data, column) {
		var value = event.target.value;
		var target = event.target.name
		data[column.id] = value;
		this.updatePlayer("change", data).then((result) => {
			try {
				result.json().then((json) => {
					if (json && json.success) {
						this.setState({[target]: value});
					}
				});
			} catch (e) {}
		});
	}

	renderCell(data, column) {
		switch (column.type) {
			case "button":
				return <input type="button" name={column.id + data.id} value={column.info.label} onClick={(event) => this.onButton(event, data, column)} />
			case "password":
				return <div>
						<input type="password" name={column.id + data.id} id={column.id + data.id} value={data[column.id] || ""} placeholder="*****" onChange={(event) => this.onPasswordChange(event, data, column)} />
					<input type="button" name={column.id + data.id} value="->" onClick={(event) => this.onSavePassword(event, data, column)} />
					</div>
			case "text":
				return <input type="text" name={column.id + data.id} value={data[column.id] || ""} onChange={(event) => this.onTextChange(event, data, column)}/>
			case "select":
				return <select name={column.id + data.id} value={data[column.id]} onChange={(event) => this.onTextChange(event, data, column)}>
					{
						column.info.options.map((sel) => {
							return <option key={column.id + data.id + sel.value} value={sel.value}>{sel.label}</option>
						})
					}
				</select>
			case "checkbox":
				var attr = {};
				if (data[column.id] === column.info["selected"]) {
					attr["defaultChecked"] = "defaultChecked";
				}
				return <input type="checkbox" value="remember" onClick={(event) => this.onCheckbox(event, data, column)} {...attr}/>
			default:
				return <div>-</div>

		}
	}

	addColumn(list, type, id, label, info) {
		var column = {
			type: type,
			label: label,
			id: id,
			info: info,
			labelField: id,
		}
		switch (type) {
			case "radOnly":
				column.renderType = "simple";
				break;
			case "checkbox":
			case "text":
			case "select":
			case "password":
			case "button":
				column.renderType = "custom";
				column.renderCell = (data, col) => this.renderCell(data, col);
				break;
			default:
				column.renderType = "simple";
				break;

		}

		list.push(column);
	}

	addPlayer() {
		var nick = document.getElementById("nickInput").value;
		var pw = document.getElementById("passwordInput").value;
		if (nick && nick.length > 0) {
			this.updatePlayer("add", {
				"nick": nick,
				"pw": pw
			}).then((result) => {
				try {
					result.json().then((json) => {
						if (json && json.success) {
							this.state.gridConfiguration.data.push(json.player);
							this.setState({"gridConfiguration": this.state.gridConfiguration});
						}
					});
				} catch (e) {}
			})
		}
	}

	updatePlayer(whatToDo, player) {
		if (player.nick === this.user.nick && (whatToDo === "delete" || player.access !== "SUPERADMIN")) {
			return Promise.reject("do not edit yourself");
		}
		return UserService.updatePlayer(this.user.nick, this.user.pw, whatToDo, player);
	}

	loadData(user) {
		this.user = user;
		UserService.getPlayerEntryData(user.nick, user.pw).then((result) => {
			try {
				result.json().then(data => {
					var gridConfiguration = {
						columns: [],
						data: data.players,
					}

					this.addColumn(gridConfiguration.columns, "readOnly", "id", "Id");
					this.addColumn(gridConfiguration.columns, "readOnly", "nick", "Nick");
					this.addColumn(gridConfiguration.columns, "password", "pw", "Password");
					this.addColumn(gridConfiguration.columns, "text", "fullname", "Name");
					this.addColumn(gridConfiguration.columns, "text", "mail", "Mail");
					this.addColumn(gridConfiguration.columns, "text", "phone", "Phone");
					this.addColumn(gridConfiguration.columns, "select", "access", "Access", {
						"options": [
							{
								"value": "NO_ACCESS",
								"label": "Kein Zugriff",
							}, {
								"value": "USER",
								"label": "User",
							}, {
								"value": "ADMIN",
								"label": "Admin",
							}, {
								"value": "SUPERADMIN",
								"label": "Super admin",
							},
						]
					});
					this.addColumn(gridConfiguration.columns, "checkbox", "active", "Active", {
						"selected": "active",
						"notSelected": "inactive",
					});
					this.addColumn(gridConfiguration.columns, "button", "delete", "Delete", {"label": "X"});
					this.setState({"gridConfiguration": gridConfiguration});
				}, error => {
					this.setState({error: "Error parsing list data"});
				});
			} catch (e) {
				this.setState({error: "No list data"});
			} finally {}
		})
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => this.state.error
					? (<div>SignInGrid error {this.state.error}</div>)
					: this.state.gridConfiguration
						? (<div id="adminKickers">
							<div id="inserRow">
								<label>
									<b>Neuer Spieler:</b>
								</label>
								<input type="text" id="nickInput"/>
								<input type="text" id="passwordInput" defaultValue="password"/>
								<input type="button" value="+" onClick={(event) => this.addPlayer()}/>
							</div>
							<DataGrid configuration={this.state.gridConfiguration}/>
						</div>)
						: this.loadData(user.currentUser)

			}

		</CurrentUserContext.Consumer>)
	}
}
export default AdminKickers;
