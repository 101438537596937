import React from 'react';
import "./UserData.css";
import {CurrentUserContext, UserService,} from "../../../../../common/webservice/user/User.js";

class UserData extends React.Component {
	constructor(props) {
		super(props);
		this.user = undefined;
		this.state = {
			user: undefined
		}
	}

	update() {
		if (this.state.user.pw.length === 0) {
			window.alert("Ein Passwort muss schon sein!");
		}
		UserService.updateUserData(this.user.nick, this.user.pw, "change", this.state.user).then((result) => {
			try {
				result.json().then((json) => {
					if (json && json.success) {
						this.setUser({
							...this.state.user
						});
						/*this.user.pw = this.state.user.pw;
						this.user.active = this.state.user.active;
						this.user.phone = this.state.user.phone;
						this.user.mail = this.state.user.mail;
						this.user.fullName = this.state.user.fullName;*/
						if (this.state.user.remember) {
							localStorage.setItem('currentUser', JSON.stringify(this.state.user));
						}
					}
				});
			} catch (e) {}

		})
	}

	onCheckbox(event) {
		var active = event.currentTarget.checked
			? "active"
			: "inactive";

		this.setState(prevState => ({
			user: { // object that we want to update
				...prevState.user, // keep all other key-value pairs
				active: active,
			}
		})); // update the value of specific key
	}

	updateUser(event, key) {
		var obj = {
			...this.state.user
		}
		obj[key] = event.target.value;
		this.setState({"user": obj});
	}

	setUserData(user) {
		if (this.state.user === undefined) {
			this.user = user.currentUser;
			this.setUser = user.setUser;
			setTimeout(() => {
				this.setState({
					"user": JSON.parse(JSON.stringify(this.user))
				});
			})
		}
	}
	componentWillUnmount() {
		this.user = undefined;
		this.setUser = undefined;
	}

	getForm() {
		var attr = {};
		if (this.state.user.active === "active") {
			attr["defaultChecked"] = "defaultChecked";
		}

		return (<form id="userData">
			<h3>Meine Daten - {this.state.user.nick}</h3>
			<div className="userRow">
				<label className="formLabel">Password:
				</label>
				<input type="password" id="password" value={this.state.user.pw} onChange={(event) => this.updateUser(event, "pw")}></input>
			</div>
			<div className="userRow">
				<label className="formLabel">Name:
				</label>
				<input type="text" id="fullName" value={this.state.user.fullName} onChange={(event) => this.updateUser(event, "fullName")}></input>
			</div>
			<div className="userRow">
				<label className="formLabel">Telefon:
				</label>
				<input type="text" id="phone" value={this.state.user.phone} onChange={(event) => this.updateUser(event, "phone")}></input>
			</div>
			<div className="userRow">
				<label className="formLabel">E-Mail:
				</label>
				<input type="text" id="mail" value={this.state.user.mail} onChange={(event) => this.updateUser(event, "mail")}></input>
			</div>
			<div className="userRow">
				<input type="checkbox" id="isAcive" value="isActive" onClick={(event) => this.onCheckbox(event)} {...attr}/>
				<label>Aktiver Spieler</label>
			</div>
			<div className="userRow">
				<input type="button" value="Daten speichern" onClick={() => this.update()}/>
			</div>
		</form>)
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => this.user
					? this.getForm()
					: this.setUserData(user)
			}
		</CurrentUserContext.Consumer>)
	}
}
export default UserData;
