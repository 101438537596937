import React from 'react';
import "./Login.css"
import {CurrentUserContext, UserService} from '../../../common/webservice/user/User.js';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: undefined
		};
		//this.checkLogin();
		this.userName = "";
		this.password = "";
		this.remember = true;
	}

	checkLogin(setUser) {
		UserService.tryLogin(this.userName, this.password).then((data) => {
			try {
				data.json().then(data => {
					data.pw = this.password;
					setUser(data, this.remember);
					//this.setState({user: data, erorr: undefined});
				}, error => {
					this.setState({error: "Wrong login"});
				});
			} catch (e) {
				this.setState({error: "Wrong login"});
			} finally {}
		})
	}

	updateUser(event) {
		this.userName = event.currentTarget.value;
	}

	updatePassword(event) {
		this.password = event.currentTarget.value;
	}
	setRemeber(event) {
		this.remember = event.currentTarget.checked;
	}

	onKey(event, setUser) {

		switch (event.charCode) {
			case 13:
				this.checkLogin(setUser);
				break;
			default:
				break;
		}
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				({user, setUser}) => (<form id="loginForm">
					<h3>Login</h3>
					{
						this.state.error
							? (<div className="error">{this.state.error}
							</div>)
							: ("")
					}
					<div className="loginRow">
						<label className="formLabel">User:
						</label>
						<input type="text" id="userName" onChange={(event) => this.updateUser(event)}></input>
					</div>
					<div className="loginRow">
						<label className="formLabel">Password:
						</label>
						<input type="password" id="password" onChange={(event) => this.updatePassword(event)} onKeyPress={(event) => this.onKey(event, setUser)}></input>
					</div>
					<div className="loginRow">
						<input type="checkbox" value="remember" onClick={(event) => this.setRemeber(event)} defaultChecked="defaultChecked"/>
						<label>Login merken</label>
					</div>
					<div className="loginRow">
						<input type="button" value="Login" onClick={() => this.checkLogin(setUser)}/>
					</div>
				</form>)
			}
		</CurrentUserContext.Consumer>)
	}
}
export default Login;
