import React from 'react';
import "./Dashboard.css";
import Statistic from './statistic/Statistic.js';
import NextDates from './nextDates/NextDates.js';
import {CurrentUserContext, UserService,} from "../../../../../common/webservice/user/User.js";

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			statistic: undefined
		};
	}

	loadData(user) {
		UserService.getSmallStatistic(user.nick, user.pw).then((data) => {
			try {
				data.json().then(data => {
					this.setState({statistic: data});
				}, error => {
					this.setState({error: "No statistic"});
				});
			} catch (e) {
				this.setState({error: "No statistic"});
			} finally {}
		})
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => this.state.error
					? (<div>Dashboard for user {this.state.error}</div>)
					: this.state.statistic
						? (<div>
							<Statistic stat={this.state.statistic}></Statistic>
							<NextDates></NextDates>
						</div>)
						: this.loadData(user.currentUser)

			}

		</CurrentUserContext.Consumer>)
	}
}
export default Dashboard;
