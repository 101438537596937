import React from 'react';
import "./AdminDates.css";
import {CurrentUserContext, UserService,} from "../../../../../common/webservice/user/User.js";
import DataGrid from "../../../../../common/components/dataGrid/DataGrid";

class AdminDates extends React.Component {
	constructor(props) {
		super(props);
		this.updateCount = 0;
		this.user = undefined;
		this.state = {
			gridConfiguration: undefined
		};
	}

	onCheckbox(event, data, column) {
		if (event.currentTarget.checked) {
			data[column.labelField] = column.info["selected"];
		} else {
			data[column.labelField] = column.info["notSelected"];
		}
		this.updateDate("change", data);
		this.setState({
			[event.target.name]: data[column.labelField]
		});
	}

	onButton(event, data, column) {
		switch (column.id) {
			case "delete":
				this.updateDate("delete", data).then(() => {
					var index = this.state.gridConfiguration.data.indexOf(data);
					if (index >= 0) {
						this.state.gridConfiguration.data.splice(index, 1);

						this.updateCount++;
						this.setState({gridConfiguration: this.state.gridConfiguration}); // update the value of specific key
					}
				});
				break;
			default:

		}
	}

	onTextChange(event, data, column) {
		data[column.labelField] = event.target.value;
		this.updateDate("change", data);

		this.updateCount++;
		this.setState(prevState => ({
			gridConfiguration: {
				...prevState.gridConfiguration,
				updateCount: this.updateCount
			}
		}));
	}

	renderCell(data, column) {
		switch (column.type) {
			case "button":
				return <input type="button" name={column.id + data.id} value={column.info.label} onClick={(event) => this.onButton(event, data, column)}/>
			case "text":
				return <input type="text" name={column.id + data.id} value={data[column.id]} onChange={(event) => this.onTextChange(event, data, column)}/>
			case "checkbox":
				var attr = {};
				if (data[column.id] === column.info["selected"]) {
					attr["defaultChecked"] = "defaultChecked";
				}
				return <input type="checkbox" value="remember" onClick={(event) => this.onCheckbox(event, data, column)} {...attr}/>
			default:
				return <div>-</div>

		}
	}

	addColumn(list, type, id, label, info) {
		var column = {
			type: type,
			label: label,
			id: id,
			info: info,
			labelField: id,
		}
		switch (type) {
			case "radOnly":
				column.renderType = "simple";
				break;
			case "checkbox":
			case "text":
			case "button":
				column.renderType = "custom";
				column.renderCell = (data, col) => this.renderCell(data, col);
				break;
			default:
				column.renderType = "simple";
				break;

		}

		list.push(column);
	}

	addDate() {
		var fromDateString = document.getElementById("fromDate").value;
		var toDateString = document.getElementById("toDate").value;
		if (fromDateString.length > 0) {
			if (toDateString === "") {
				toDateString = fromDateString;
			}
			var fromDate = new Date(fromDateString);
			var toDate = new Date(toDateString);
			var toTime = toDate.getTime();
			while (fromDate.getTime() <= toTime) {
				this.updateDate("add", {
					"dateValue": this.formatDate(fromDate),
					"season": this.state.gridConfiguration.previewSeason
				}).then((result) => {
					try {
						result.json().then((json) => {
							if (json && json.success) {
								this.updateCount++;
								this.state.gridConfiguration.data.splice(0, 0, {
									...json.date,
									id: json.date.Id || json.date.dateId,
									ts: new Date(json.date.dateValue),
								});

								this.setState(prevState => ({
									gridConfiguration: {
										...prevState.gridConfiguration,
										data: this.state.gridConfiguration.data,
										updateCount: this.updateCount,
									}
								}));
							}
						});
					} catch (e) {}
				})
				fromDate.setDate(fromDate.getDate() + 7);
			}

		}
	}

	updateDate(whatToDo, date) {
		return UserService.updateDate(this.user.nick, this.user.pw, whatToDo, date);
	}

	addZero(number) {
		var n = number.toString();
		if (n.length === 1) {
			n = "0" + n;
		}
		return n;
	}

	previewSeasonChanged(event) {
		var previewSeason = event.target.value;
		this.setState(prevState => ({
			gridConfiguration: { // object that we want to update
				...prevState.gridConfiguration, // keep all other key-value pairs
				previewSeason: previewSeason
			}
		})); // update the value of specific key
	}

	updateSeason(increase) {
		var currentSeason = parseInt(this.state.gridConfiguration.currentSeason) + parseInt(increase);
		UserService.updateSeason(this.user.nick, this.user.pw, "change", currentSeason).then((result) => {
			try {
				result.json().then((json) => {
					if (json && json.success) {
						/*this.setState(prevState => ({
							gridConfiguration: { // object that we want to update
								...prevState.gridConfiguration, // keep all other key-value pairs
								currentSeason: currentSeason
							}
						}));*/
						this.loadData(this.user);
					}
				});
			} catch (e) {}
		})

	}

	formatDate(date) {
		return date.getFullYear() + "-" + this.addZero(date.getMonth() + 1) + "-" + this.addZero(date.getDate());
	}

	loadData(user) {
		this.user = user;
		UserService.getDateEntryData(user.nick, user.pw).then((result) => {
			try {
				result.json().then(data => {

					if (data.dates) {
						data.dates.forEach(function(date) {
							date.id = date.dateId;
							date.ts = new Date(date.dateValue)
						});
						var dateCompare = function(a, b) {
							return b.ts - a.ts
						}

						data.dates.sort(dateCompare);
					}

					var columns = [];
					this.addColumn(columns, "readOnly", "dateId", "Id");
					this.addColumn(columns, "readOnly", "dateValue", "Date");
					this.addColumn(columns, "readOnly", "season", "Season");
					this.addColumn(columns, "text", "freeText", "Free text");
					this.addColumn(columns, "checkbox", "noKick", "No kick", {
						"selected": "NOKICK",
						"notSelected": "",
					});
					this.addColumn(columns, "text", "turnament", "Turnament");
					this.addColumn(columns, "button", "delete", "Delete", {"label": "X"});
					//this.setState({"gridConfiguration": gridConfiguration});

					var config = this.state.gridConfiguration;
					if (config === undefined) {
						config = {
							currentSeason: parseInt(data.currentSeason),
							previewSeason: parseInt(data.currentSeason),
							columns: columns,
							data: data.dates || []
						}
					} else {
						config.currentSeason = parseInt(data.currentSeason);
						config.previewSeason = parseInt(data.currentSeason);
						config.columns = columns;
						if (config.data.length > 0) {
							config.data.splice(0, config.data.length);
						}
						config.data = data.dates || []
					}
					this.setState({gridConfiguration: config});
				}, error => {
					this.setState({error: "Error parsing list data"});
				});
			} catch (e) {
				this.setState({error: "No list data"});
			} finally {}
		})
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => this.state.error
					? (<div>SignInGrid error {this.state.error}</div>)
					: this.state.gridConfiguration
						? (<div id="adminDates">
							<div className="insertRow">
								<label>
									<b>Neues Datum:</b>
								</label>
								<input type="date" id="fromDate"/>
								<input type="date" id="toDate"/>
								<input type="number" id="seaon" name="seasonSelector" onChange={(event) => this.previewSeasonChanged(event)} value={this.state.gridConfiguration.previewSeason}/>
								<input type="button" value="+" onClick={(event) => this.addDate()}/>
							</div>
							{
								user.currentUser.access === "SUPERADMIN"
									? (<div className="insertRow">
										<label>
											<b>Aktuelle Saison:{this.state.gridConfiguration.currentSeason}
											</b>
										</label>
										<input type="button" value="+" onClick={(event) => this.updateSeason(1)}/>
										<input type="button" value="-" onClick={(event) => this.updateSeason(-1)}/>
									</div>)
									: ""
							}
							<DataGrid configuration={this.state.gridConfiguration}/>
						</div>)
						: this.loadData(user.currentUser)

			}

		</CurrentUserContext.Consumer>)
	}
}
export default AdminDates;
