import React from 'react';

export const USER_TYPES = {
	SUPERADMIN: 'SUPERADMIN',
	ADMIN: 'ADMIN',
	USER: 'USER',
};

var php_path = window.location.href.indexOf("http://localhost") === 0
	? "http://localhost:8010/"
	: "./php/";

export const UserService = {
	tryLogin: function(userName, password) {
		return fetch(php_path + "check_login.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify({"abc": userName, "def": password,}),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	getSmallStatistic: function(userName, password) {
		return fetch(php_path + "getSmallStatistic.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify({"abc": userName, "def": password,}),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	getNextDates: function(userName, password) {
		return fetch(php_path + "getNextDates.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify({"abc": userName, "def": password,}),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	getTimetable: function(userName, password) {
		return fetch(php_path + "getTimetable.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify({"abc": userName, "def": password,}),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	getDateEntryData: function(userName, password) {
		return fetch(php_path + "getDateEntryData.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify({"abc": userName, "def": password,}),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	getPlayerEntryData: function(userName, password) {
		return fetch(php_path + "getPlayerEntryData.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify({"abc": userName, "def": password,}),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	updateYesNo: function(userName, password, nick, dateId, selectedIndex) {
		var body = {
			"abc": userName,
			"def": password,
			"data": {
				"player": nick,
				"selectedIndex": selectedIndex,
				"dateId": dateId,
			},
		};
		return fetch(php_path + "updateyesno.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	updateDate: function(userName, password, whatToDo, date) {
		var body = {
			"abc": userName,
			"def": password,
			"data": {
				"date": date,
				"whatToDo": whatToDo
			},
		};
		return fetch(php_path + "updateDates.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	updatePlayer: function(userName, password, whatToDo, player) {
		var body = {
			"abc": userName,
			"def": password,
			"data": {
				"player": player,
				"whatToDo": whatToDo
			},
		};
		return fetch(php_path + "updatePlayer.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	updateSeason: function(userName, password, whatToDo, season) {
		var body = {
			"abc": userName,
			"def": password,
			"data": {
				"season": season,
				"whatToDo": whatToDo
			},
		};
		return fetch(php_path + "updateSeason.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
	updateUserData: function(userName, password, whatToDo, player) {
		var body = {
			"abc": userName,
			"def": password,
			"data": {
				"player": player,
				"whatToDo": whatToDo
			},
		};
		return fetch(php_path + "updateUserData.php", {
			mode: "cors",
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			},
		})
	},
}

export const CurrentUserContext = React.createContext({
	user: undefined,
	setUser: () => {}
});
