import React from 'react';
import "./SignInButton.css";
import {CurrentUserContext, UserService} from "../../../common/webservice/user/User.js";

class SignInButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIndex: props.selectedIndex
		};
	}

	signIn(user) {
		if (user.access !== "SUPERADMIN" && user.access !== "ADMIN" && user.nick !== this.props.nick) {
			return;
		}
		var selectedIndex = this.state.selectedIndex === 2
			? 1
			: 2;
		UserService.updateYesNo(user.nick, user.pw, this.props.nick, this.props.dateId, selectedIndex).then((data) => {
			try {
				data.json().then(data => {
					if (this.props.updatelist !== undefined) {

						this.props.updatelist(this.props.nick, selectedIndex, this.props.source);
					}
					this.setState({selectedIndex: selectedIndex});
				}, error => {
					this.setState({error: "No statistic"});
				});
			} catch (e) {
				this.setState({error: "No statistic"});
			} finally {}
		})
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => (<div className="sign-in-button-container">
					<div className={"sign-in-button yes " + (
							this.state.selectedIndex === 2
							? "selected"
							: "")} onClick={() => this.signIn(user.currentUser)}>J</div>
					<div className={"sign-in-button no " + (
							this.state.selectedIndex === 1
							? "selected"
							: "")} onClick={() => this.signIn(user.currentUser)}>N</div>
				</div>)

			}

		</CurrentUserContext.Consumer>)
	}
}
export default SignInButton;
