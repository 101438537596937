import React from 'react';
//import logo from './logo.svg';
import Main from './main/Main.js';
import './App.css';

function App() {
	return (<div className="App">
		<header className="App-header"></header>
		<Main></Main>
	</div>);
}

export default App;
