import React from 'react';
import "./SignInGrid.css";
import {CurrentUserContext, UserService} from "../../../../../common/webservice/user/User.js";
import DataGrid from "../../../../../common/components/dataGrid/DataGrid";
import SignInButton from "../../../../../common/components/signInButton/SignInButton.js";

class SignInGrid extends React.Component {
	constructor(props) {
		super(props);
		this.user = undefined;
		this.state = {
			gridConfiguration: undefined
		};
	}

	update(nick, selectedIndex, column) {
		/*this.state.gridConfiguration.data.forEach((player) => {
			if(player.nick === nick)
			{

			}
		});*/
	}

	renderCell(player, column) {
		switch (column.type) {
			case "Date":
				if (column.readOnly) {
					return <div className="noKick">---</div>
				}
				if (this.user.nick !== player.nick && this.user.access !== "SUPERADMIN" && this.user.access !== "ADMIN") {
					return <div class={"not-my-user " + player.dates[column.id]}>{player.dates[column.id]}</div>
				}
				var selectedIndex = 0;
				if (player.dates[column.id] === "yes") {
					selectedIndex = 2;
				} else if (player.dates[column.id] === "no") {
					selectedIndex = 1;
				}

				return <SignInButton nick={player.nick} updatelist={this.update} source={column} dateId={column.id} selectedIndex={selectedIndex}/>
			default:
				return <div>-</div>

		}
	}

	addColumn(list, type, data) {
		var column = {
			type: type
		}
		switch (type) {
			case "Player":
				column.renderType = "simple";
				column.labelField = "nick";
				column.label = "Player";
				column.id = "Player";
				break;
			case "Date":
				var tmp = data.dateValue.split("-");
				column.renderType = "custom";
				column.label = tmp[2] + "." + tmp[1] + "." + tmp[0];
				column.id = data.dateId;
				if (data.noKick && data.noKick.length > 0) {
					column.label += "\n No kick";
					column.readOnly = true;
				}
				if (data.freeText && data.freeText.length > 0) {
					column.label += "\n" + data.freeText;
				}
				if (data.turnament && data.turnament.length > 0) {
					column.label += "\n" + data.turnament;
				}
				column.renderCell = (col, player) => this.renderCell(col, player)
				break;
			default:

		}

		list.push(column);
	}

	loadData(user) {
		this.user = user;
		UserService.getTimetable(user.nick, user.pw).then((result) => {
			try {
				result.json().then(data => {
					var mySort = function(a, b) {
						if (a.nick === "GUEST3") {
							return 1;
						}
						if (b.nick === "GUEST3") {
							return -1;
						}
						if (a.nick === "GUEST2") {
							return 1;
						}
						if (b.nick === "GUEST2") {
							return -1;
						}
						if (a.nick === "GUEST1") {
							return 1;
						}
						if (b.nick === "GUEST1") {
							return -1;
						}
						if (a.nick < b.nick) {
							return -1;
						}
						if (a.nick > b.nick) {
							return 1;
						}
						return 0;
					}
					if (data.kickers) {
						data.kickers.sort(mySort);
					}

					var gridConfiguration = {
						config: data.config,
						columns: [],
						data: data.kickers
					}
					data.kickers.forEach(function(kicker) {
						kicker.id = kicker.nick;
					});
					this.addColumn(gridConfiguration.columns, "Player", "Player");
					data.dates.forEach((date) => this.addColumn(gridConfiguration.columns, "Date", date));
					this.setState({"gridConfiguration": gridConfiguration});
				}, error => {
					this.setState({error: "Error parsing list data"});
				});
			} catch (e) {
				this.setState({error: "No list data"});
			} finally {}
		})
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => this.state.error
					? (<div>SignInGrid error {this.state.error}</div>)
					: this.state.gridConfiguration
						? (<div id="signInGrid"><DataGrid configuration={this.state.gridConfiguration}/></div>)
						: this.loadData(user.currentUser)

			}

		</CurrentUserContext.Consumer>)
	}
}
export default SignInGrid;
