import React from 'react';
import "./Statistic.css"

class Statistic extends React.Component {
	render() {
		return (<div className="statistic">
			<div className="stats-row">
				<label className="stats-label">Nächstes Spiel:
				</label>
				<label className="stats-value">{
						this.props.stat.season.nextDate === 0
							? "--"
							: this.props.stat.season.nextDate
					}</label>
			</div>

			<div className="stats-row">
				<label className="stats-label">Spiele bisher:
				</label>
				<label className="stats-value">{this.props.stat.stats.gamesPlayed}</label>
			</div>

			<div className="stats-row">
				<label className="stats-label">Noch zu spielen:
				</label>
				<label className="stats-value">{this.props.stat.stats.gamesToPlay}</label>
			</div>
		</div>)
	}
}
/*<div className="stats-row">
				<label className="stats-label">Spieler angemeldet:
				</label>
				<label className="stats-value">{this.props.stat.stats.gamesToPlay}</label>
			</div>*/
export default Statistic;
