import React from 'react';
import "./DataGrid.css";

class DataGrid extends React.Component {
	constructor(props) {
		super(props);
		this.updateNeeded = false;
		this.state = {
			gridConfiguration: props.configuration
		};
	}
	/*
	shouldComponentUpdate(newConfig, newState) {
		console.log("DataGrid shouldComponentUpdate " + newConfig.configuration.updateCount);
		if (this.updateNeeded) {
			this.updateNeeded = false;
			return true;
		}
		if (newConfig && newConfig.configuration) {
			if (!this.props || !this.props.configuration) {
				return true;
			} else {
				//var shouldUpdate = newConfig.configuration.updateCount !== this.props.configuration.updateCount;
				var shouldUpdate = JSON.stringify(newConfig.configuration.data) !== JSON.stringify(this.props.configuration.data);

				if (shouldUpdate) {
					this.updateNeeded = true;
					this.setState({"gridConfiguration": newConfig.configuration})
				}
				return shouldUpdate;
			}
		}
		return false;
	}*/

	createHeaderCell(column) {
		return (<th key={column.id}>{column.label}</th>);
	}

	createRow(data) {
		var columns = [].concat(this.state.gridConfiguration.columns);
		return (<tr key={"row" + data.id}>
			{
				columns.map((column) => {
					return column.renderType === "simple"
						? (<td className="cell simple" key={data.id + column.id}>{data[column.labelField]}</td>)
						: (<td className="cell complex" key={data.id + column.id}>
							{column.renderCell(data, column)}
						</td>)

				})
			}
		</tr>)
	}

	createRows() {
		return (
			this.state.gridConfiguration.data
			? (this.state.gridConfiguration.data.map((data) => {
				return this.createRow(data);
			}))
			: (<div></div>))
	}

	render() {
		if (!this.state.gridConfiguration || !this.state.gridConfiguration.columns) {
			return (<div>loading</div>)
		}
		return (<table className="data-grid">
			{
				!this.state.gridConfiguration || !this.state.gridConfiguration.columns
					? (<div>loading</div>)
					: (<thead>
						<tr>
							{
								this.state.gridConfiguration.columns.map((column) => {
									return this.createHeaderCell(column);
								})
							}
						</tr>
					</thead>)
			}
			<tbody>
				{this.createRows()}
			</tbody>
		</table>)
	}
}
export default DataGrid;
