import React from 'react';
import "./NextDates.css";
import {CurrentUserContext, UserService,} from "../../../../../../common/webservice/user/User.js";
import SignInButton from "../../../../../../common/components/signInButton/SignInButton.js";

class NextDates extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dates: undefined
		};
		this.update = this.update.bind(this)
	}

	loadData(user) {
		UserService.getNextDates(user.nick, user.pw).then((data) => {
			try {
				data.json().then(data => {
					this.setState({dates: data});
				}, error => {
					this.setState({error: "No next Dates"});
				});
			} catch (e) {
				this.setState({error: "No next Dates"});
			} finally {}
		})
	}

	update(nick, selectedIndex, source) {
		if (selectedIndex === 2) {
			source.push(nick);
		} else if (source.indexOf(nick) >= 0) {
			source.splice(source.indexOf(nick), 1);
		}
		this.setState(this.state);
	}

	render() {
		return (<CurrentUserContext.Consumer>
			{
				(user) => this.state.error
					? (<div>{this.state.error}Test
					</div>)
					: this.state.dates
						? (<div className="next-game">
							{
								this.state.dates.map((data, index) => {
									return <div key={data.dateValue} className="next-box">
										<div>{
												index === 0
													? "Nächster"
													: "Übernächster"
											}
											Termin:
											<b>{data.dateValue}</b>
										</div>
										<div>Angemeldet:
											<b>{data.players.length}</b>
										</div>
										{
											user.currentUser.access && user.currentUser.access !== "GUEST"
												? (<div className="sign-me-in">Ich bin dabei
													<SignInButton nick={user.currentUser.nick} updatelist={this.update} source={data.players} dateId={data.dateId} selectedIndex={data.players.indexOf(user.currentUser.nick) >= 0
															? 2
															: 1}></SignInButton>
												</div>)
												: (<div></div>)
										}
										<ul className="next-player-list">
											{
												data.players.map((player) => {
													return <li key={player}>{player}</li>
												})
											}
										</ul>
									</div>
								})
							}
						</div>)
						: this.loadData(user.currentUser)
			}

		</CurrentUserContext.Consumer>)
	}
}
export default NextDates;
