import React from 'react';
import "./Main.css"
import Login from "./components/login/Login.js"
import CommandCenter from "./components/commandCenter/CommandCenter.js"
import {CurrentUserContext} from "../common/webservice/user/User.js"

class Main extends React.Component {
	constructor(props) {
		super(props);
		var tmp = localStorage.getItem('currentUser');
		var user;
		try {
			user = JSON.parse(tmp);
		} catch (e) {} finally {}
		this.state = {
			currentUser: user,
			setUser: this.setUser
		};
	}

	setUser = (user, remember) => {
		if (remember) {
			user.remember = true;
			localStorage.setItem('currentUser', JSON.stringify(user));
		} else {
			localStorage.removeItem('currentUser');
		}
		this.setState(state => ({currentUser: user}));
	};

	logout = () => {
		localStorage.removeItem('currentUser');
		this.setState(state => ({currentUser: undefined}));
	}

	render() {
		return (<CurrentUserContext.Provider value={this.state}>
			{
				this.state.currentUser
					? (<div>
						<CommandCenter></CommandCenter>
					</div>)
					: (<Login></Login>)
			}
		</CurrentUserContext.Provider>)
	}
}
export default Main;
